import {
  SET_CONTEXT_SIDEBAR,
  SET_HEADER_OPEN,
} from "./app-constants"

const initialState = {
  course: null,
  module: null,
  parentTopic: null,
  topic: null,
}

export const appReducer = (state = initialState, action) => {
  let type
  switch (action.type) {
    case SET_CONTEXT_SIDEBAR:
      var sidebar = action.payload

      var _state = { ...state }

      if(sidebar == null) return null;

      if (state?.course?.slug != sidebar?.course?.slug) {
        _state.course = sidebar.course
      }
      if (state?.module?.slug != sidebar?.module?.slug) {
        _state.module = sidebar.module
      }
      if (state?.parentTopic?.slug != sidebar?.parentTopic?.slug) {
        _state.parentTopic = sidebar.parentTopic
      }
      if (state?.topic?.slug != sidebar?.topic?.slug) {
        _state.topic = sidebar.topic
      }

      return _state
    case SET_HEADER_OPEN:
      type = action.payload.type
      var val = action.payload.val

      var obj = state[type]

      return {
        ...state,
        [type]: { ...obj, headerOpen: val },
      }

    default:
      return state
  }
}
