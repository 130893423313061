exports.components = {
  "component---src-components-admin-page-jsx": () => import("./../../../src/components/admin/page.jsx" /* webpackChunkName: "component---src-components-admin-page-jsx" */),
  "component---src-components-classroom-course-course-jsx": () => import("./../../../src/components/classroom/course/course.jsx" /* webpackChunkName: "component---src-components-classroom-course-course-jsx" */),
  "component---src-components-classroom-module-module-jsx": () => import("./../../../src/components/classroom/module/module.jsx" /* webpackChunkName: "component---src-components-classroom-module-module-jsx" */),
  "component---src-components-classroom-module-module-test-jsx": () => import("./../../../src/components/classroom/module/module-test.jsx" /* webpackChunkName: "component---src-components-classroom-module-module-test-jsx" */),
  "component---src-components-classroom-topic-templates-4-x-8-content-jsx": () => import("./../../../src/components/classroom/topic/templates/4x8content.jsx" /* webpackChunkName: "component---src-components-classroom-topic-templates-4-x-8-content-jsx" */),
  "component---src-components-classroom-topic-templates-tabtemplate-4-x-8-jsx": () => import("./../../../src/components/classroom/topic/templates/tabtemplate-4x8.jsx" /* webpackChunkName: "component---src-components-classroom-topic-templates-tabtemplate-4-x-8-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-classroom-js": () => import("./../../../src/pages/classroom.js" /* webpackChunkName: "component---src-pages-classroom-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-secret-register-js": () => import("./../../../src/pages/secret-register.js" /* webpackChunkName: "component---src-pages-secret-register-js" */),
  "component---src-pages-sign-up-[id]-jsx": () => import("./../../../src/pages/sign-up/[id].jsx" /* webpackChunkName: "component---src-pages-sign-up-[id]-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

