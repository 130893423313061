import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from "redux"

import thunk from "redux-thunk"

import { appReducer } from "./app-reducer"
const reducer = combineReducers({ app: appReducer })

const createStore = reduxCreateStore(reducer, {}, applyMiddleware(...[thunk]))

export default createStore
